document.addEventListener('DOMContentLoaded', function () {
    const feedbackBlock = $('.feedbackForm__block');
    const feedbackForm = $('.feedbackForm');
    const feedbackFormHeader = $('.feedbackForm__header:first');
    const feedbackFormBody = $('.feedbackForm__body:first');
    const feedbackFormHeaderSuccess = $('.feedbackForm__header__success');
    const feedbackFormBodySuccess = $('.feedbackForm__body__success');
    const feedbackFormHeaderError = $('.feedbackForm__header__error');
    const feedbackFormBodyError = $('.feedbackForm__body__error');
    const feedbackFormPreviewCircle = $('.feedbackForm__preview__circle');
    const feedbackBackground = $('.feedbackForm__background');

    window.showFeedbackForm = () => {
        const cookie = $('.cookiesPopup');
        let feedbackFormBottom = '70px';
        if (cookie.is(':visible') && ($.cookie('locale') || $(window).width() >= 810)) {
            feedbackFormBottom = (cookie[0].offsetHeight + 10) + 'px';
        } else if (cookie.is(':visible') && ($.cookie('locale') || $(window).width() >= 320)) {
            feedbackFormBottom = (cookie[0].offsetHeight + 10) + 'px';
        }
        feedbackBlock.css('bottom', feedbackFormBottom).show();
    };

    showFeedbackForm();

    feedbackFormPreviewCircle.click(function () {
        $(this).hide();
        $(feedbackForm).show();
    });

    $('.feedBackForm__close__btn, .feedbackForm__background').click(function () {
        feedbackForm.trigger('reset').hide();
        feedbackFormHeaderSuccess.hide();
        feedbackFormBodySuccess.hide();
        feedbackFormHeaderError.hide();
        feedbackFormBodyError.hide();
        feedbackFormHeader.css('display', 'flex');
        feedbackFormBody.css('display', 'flex').find('.feedbackForm__body__error__message').html('');
        feedbackFormPreviewCircle.css('display', 'flex');
        feedbackBackground.removeClass('active');
        feedbackBlock.removeClass('center').css('bottom', '');
        showFeedbackForm();
    });

    $('.feedbackForm__submit__btn').click(function (e) {
        e.preventDefault();
        $(this).prop('disabled', true);
        axios.post(FEEDBACK_QUICK_ANSWER, feedbackForm.serialize(), {
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
            },
        }).then(response => {
            feedbackFormHeader.hide();
            feedbackFormBody.hide();
            if (response.data.success) {
                feedbackFormHeaderSuccess.css('display', 'flex');
                feedbackFormBodySuccess.css('display', 'flex');
            } else {
                feedbackFormHeaderError.css('display', 'flex');
                feedbackFormBodyError.css('display', 'flex');
            }
            $(this).prop('disabled', false);
        }).catch(error => {
            feedbackFormBody
                .find('.feedbackForm__body__error__message')
                .html(Object.entries(error.response.data.errors).map(item => item[1][0]).join('<br/>'));
            $(this).prop('disabled', false);
        });
    });

    if (location.hash === '#tech-support') {
        feedbackBackground.addClass('active');
        feedbackBlock.addClass('center').css('bottom', '50%');
        feedbackFormPreviewCircle.trigger('click');
    }
});
